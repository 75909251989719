import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slide from 'react-slick';
import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import '../../../../Content/slick.min.css';

const Slider = (props) => {
    const [windowWidth] = useWindowDimensions();
    const { slideAmt, slideContent, sliderSettings, slideClass, showSlideNav } = props;
    const refSlider = useRef(null);
    const [functionsDone, setFunctionsDone] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState(slideAmt);
    const [clickPrev, setClickPrev] = useState(() => {});
    const [clickNext, setClickNext] = useState(() => {});

    useEffect(() => {
        if (refSlider.current && !functionsDone) {
            setClickPrev(() => {
                return refSlider.current.slickPrev;
            });
            setClickNext(() => {
                return refSlider.current.slickNext;
            });
            setFunctionsDone(
                typeof setClickNext !== 'undefined' && typeof setClickPrev !== 'undefined'
            );
        }
    }, [refSlider.current, functionsDone]);

    const setSlidesToShowOnReinit = (ref) => {
        const refSlidesToShow = ((((ref.current || {}).innerSlider || {}).track || {}).props || {})
            .slidesToShow;
        if (refSlidesToShow && refSlidesToShow !== slidesToShow) {
            setSlidesToShow(refSlidesToShow);
        }
    };

    const showNav = () => {
        if (slideAmt === 1 && slideContent.length > 1) {
            return true;
        }
        return slidesToShow < slideAmt;
    };

    const localSliderSettings = {
        dots: false,
        slidesToShow: slideAmt,
        slidesToScroll: slideAmt,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        infinite: true,
        arrows: false,
        initialSlide: 0,
        onReInit: () => {
            setSlidesToShowOnReinit(refSlider);
        },
    };
    const settings = { ...localSliderSettings, ...sliderSettings };

    const slides = slideContent.map((source, i) => {
        const key = source.ID || source.id || source.Id || i;
        const imageSource = ("https://cloud.squidex.io/api/assets/" + source.imageSrc || '').trim();
        let bgImage = `url(${imageSource})`;

        if (source.useViewport) {
            let imageWidth = 1600;
            if (windowWidth <= 767) {
                imageWidth = 767;
            } else if (windowWidth <= 991) {
                imageWidth = 991;
            } else if (windowWidth <= 1199) {
                imageWidth = 1199;
            }
            const extensions = /\.(jpg|bmp|jpeg|gif|png|tif|tiff|png|svg)$/gi;
            const imageExtension = (imageSource.match(extensions) || [])[0];

            if (imageExtension) {
                const imageSuffix = `-${imageWidth.toString()}${imageExtension}`;
                bgImage = `url( ${imageSource.replace(imageExtension, imageSuffix)})`;
            }
        }

        const inlineStyle = { backgroundImage: bgImage };

        const slideInnerContent = (
            <div className="item-slide" style={inlineStyle}>
                <div className="slide-desc">
                    <div className={source.descType ? `slide-desc-${source.descType}` : ''}>
                        <div className="p-primary">{source.title}</div>
                        <p>{source.desc}</p>
                    </div>
                </div>
            </div>
        );
        const slide =
            source.linkType === 'react' ? (
                <Link key={key} to={source.url}>
                    {slideInnerContent}
                </Link>
            ) : (
                <a key={key} href={source.url}>
                    {slideInnerContent}
                </a>
            );

        return slide;
    });
    return (
        <React.Fragment>
            <div className={`slick-wrapper ${slideClass}`}>
                <Slide {...settings} ref={refSlider}>
                    {slides}
                </Slide>
                {showSlideNav && showNav() ? (
                    <React.Fragment>
                        <div className="slick-controls">
                            <div className="slick-nav">
                                <button
                                    type="button"
                                    onClick={() => clickPrev()}
                                    className="slick-prev"
                                >
                                    <i className="fa fa-angle-left" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div className="slick-controls">
                            <div className="slick-nav">
                                <button
                                    type="button"
                                    onClick={() => clickNext()}
                                    className="slick-next"
                                >
                                    <i className="fa fa-angle-right" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    ''
                )}
            </div>
        </React.Fragment>
    );
};
Slider.propTypes = {
    slideAmt: PropTypes.number.isRequired,
    slideType: PropTypes.number.isRequired,
    slideStyle: PropTypes.string.isRequired,
    slideContent: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types
    sliderSettings: PropTypes.object,
    slideClass: PropTypes.string,
    showSlideNav: PropTypes.bool,
};
Slider.defaultProps = {
    slideContent: [],
    sliderSettings: {},
    slideClass: '',
    showSlideNav: false,
};

export default Slider;
