import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PVSlider from '../../Shared/Carousel/PVSlider';
import PVPartnerSliderCMS from '../../Shared/Content/PVPartnerSliderCMS';
import PVFeaturedProducts from './PVFeaturedProductsPanel/PVFeaturedProducts';
import cmsApi from '../../../helpers/cmsApi';

class PVMainDom extends React.Component {
    state = { mainSlideContent: [], topRightImage: [], bottomRightImage: [], partnerSlider: [], partnerSliderMeta: [] };

    componentDidMount() {
        const response = cmsApi.get('landingpage-dom-wire', {  // changed as per #7407
        }).then(response => {
            this.setState({
                mainSlideContent: response.data.items[0].data.mainSlideContent.iv,
                topRightImage: response.data.items[0].data.topRightImage.iv[0],
                bottomRightImage: response.data.items[0].data.bottomRightImage.iv[0]
               // partnerSlider: response.data.items[0].data.partnerSlider.iv,
              //  partnerSliderMeta: response.data.items[0].data.partnerSliderMeta.iv[0]
            });
            
        })
      

    }
    render() {
        return (
            <React.Fragment>
                <div className="block-slide">
                    <div className="container">
                        <div className="main-slide slide-opt-3 pv-DOM-slide">
                            <PVSlider
                                slideAmt={1}
                                slideType={1}
                                slideStyle="nav-style5"
                                slideContent={this.state.mainSlideContent}
                                slideClass="pv-DivisionHeader"
                                showSlideNav
                            />
                        </div>
                    <div className="promotion-banner banner-slide style-5 first">
                            <a href={this.state.topRightImage.link} className="banner-img">
                                <img
                                    src={"https://cloud.squidex.io/api/assets/" + this.state.topRightImage.src}
                                    alt={this.state.topRightImage.alt}
                                />
                            </a>
                        </div>
                    <div className="promotion-banner banner-slide style-5 second">
                            <a href={this.state.bottomRightImage.link} className="banner-img">
                                <img
                                    src={"https://cloud.squidex.io/api/assets/" + this.state.bottomRightImage.src}
                                    alt={this.state.bottomRightImage.alt}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <PVFeaturedProducts />
                {/*<PVPartnerSliderCMS*/}
                {/*    title={this.state.partnerSliderMeta.title}*/}
                {/*    sliderContent={this.state.partnerSlider}*/}
                {/*    sliderClass={this.state.partnerSliderMeta.class}*/}
                {/*/>*/}
            </React.Fragment>
        );
    }
};

export default PVMainDom;
