import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import '../../../../Content/slick.min.css';

const PVImageSlider = (props) => {
    const { slideAmt, sectionTitle, content, slideClass } = props;
    const refSlider = useRef(null);
    const [functionsDone, setFunctionsDone] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState(slideAmt);
    const [clickPrev, setClickPrev] = useState(() => {});
    const [clickNext, setClickNext] = useState(() => {});

    useEffect(() => {
        if (refSlider.current && !functionsDone) {
            setClickPrev(() => {
                return refSlider.current.slickPrev;
            });
            setClickNext(() => {
                return refSlider.current.slickNext;
            });
            setFunctionsDone(
                typeof setClickNext !== 'undefined' && typeof setClickPrev !== 'undefined'
            );
        }
    }, [refSlider.current, functionsDone]);

    const setSLidesToShowOnReinit = (ref) => {
        const refSlidesToShow = ((((ref.current || {}).innerSlider || {}).track || {}).props || {})
            .slidesToShow;
        if (refSlidesToShow && refSlidesToShow !== slidesToShow) {
            setSlidesToShow(refSlidesToShow);
        }
    };

    const sliderSettings = {
        dots: false,
        slidesToShow: slideAmt,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        initialSlide: 0,
        variableWidth: true, //enforces the div width for each slide
        onReInit: () => {
            setSLidesToShowOnReinit(refSlider);
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slideAmt - 1 || 1,
                    slidesToScroll: slideAmt - 1 || 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: slideAmt - 2 || 1,
                    slidesToScroll: slideAmt - 2 || 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: false,
                },
            },
        ],
    };

    const getEl = (source, i) => {
        const key = source.id || source.ID || source.Id || i;
        if (source.link && source.reactLink) {
            return (
                <Link key={key} to={source.link}>
                    <img src={"https://cloud.squidex.io/api/assets/" + source.imageSrc} alt={source.description} />
                </Link>
            );
        }
        if (source.link !== '#') {
            return (
                <a key={key} href={source.link}>
                    <img src={"https://cloud.squidex.io/api/assets/" + source.imageSrc} alt={source.description} />
                </a>
            );
        }
        return <img key={key} src={"https://cloud.squidex.io/api/assets/" + source.imageSrc} alt={source.description} />;
    };

    return (
        <div className="block-section-brand">
            <div className="container">
                <div className="title-of-section">{sectionTitle}</div>
                <div className={`section-brand style2 ${slideClass}`} />
                <div className={`slick-wrapper ${slideClass}`}>
                    {slidesToShow < slideAmt ? (
                        <div className="slick-controls">
                            <div className="slick-nav">
                                <button
                                    type="button"
                                    onClick={() => clickPrev()}
                                    className="slick-prev"
                                >
                                    <i className="fa fa-angle-left" aria-hidden="true" />
                                </button>

                                <button
                                    type="button"
                                    onClick={() => clickNext()}
                                    className="slick-next"
                                >
                                    <i className="fa fa-angle-right" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <Slider {...sliderSettings} ref={refSlider}>
                        {content.map((source, i) => {
                            const el = getEl(source, i);
                            const key = source.id || source.ID || source.Id || i;
                            return (
                                <div key={key} style={{ width: 175, height: 'auto' }}>
                                    {el}
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

PVImageSlider.propTypes = {
    slideAmt: PropTypes.number.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    slideClass: PropTypes.string,
};

PVImageSlider.defaultProps = {
    slideClass: '',
};

export default PVImageSlider;
